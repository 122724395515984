.developer-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  animation: blurContainer 0.7s ease-in-out 0s 1 normal forwards;
}

.developer-box {
  width: 80vw;
  background-color: rgb(240, 240, 240);
  margin: auto;
  border-radius: 4vh;
  padding-left: 4vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
  box-shadow: 0vh 2vh 3vh 0vh rgba(0, 0, 0, 0.2);
  animation: opacity0to1 0.7s ease-in-out 0s 1 normal forwards;
}

.developer1-info-container {
  display: flex;
  align-items: center;
  opacity: 0;
  animation: opacity0to1 0.5s ease-in-out 0.5s 1 normal forwards;
}

.developer1-picture {
  width: 20vw;
}

.developer1-button-container {
  display: flex;
  opacity: 0;
  animation: opacity0to1 0.5s ease-in-out 0.7s 1 normal forwards;
}

.developer1-button-instagram {
  height: 5vh;
  position: relative;
  margin-top: 1vh;
  margin-left: 3vw;
  padding-left: 1.5vh;
  padding-right: 1.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5vh;
  border-width: 0px;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  box-shadow: 0vh 0.5vh 2vh 0vh #dc274393;
  font-size: 1.5vh;
  color: white;
  font-weight: 700;
  transition: top ease 0.5s;
  cursor: pointer;
}

.instagram-icon {
  scale: 0.7;
  margin-right: 0.7vw;
}

.developer1-button-github {
  height: 5vh;
  position: relative;
  margin-top: 1vh;
  margin-left: 1vw;
  padding-left: 1.5vh;
  padding-right: 1.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5vh;
  border-width: 0px;
  background: #333;
  box-shadow: 0vh 0.5vh 2vh 0vh rgba(51, 51, 51, 0.442);
  font-size: 1.5vh;
  color: white;
  font-weight: 700;
  transition: top ease 0.5s;
  cursor: pointer;
}

.github-icon {
  scale: 0.7;
  margin-right: 0.7vw;
}

@keyframes blurContainer {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
  }
}

@keyframes opacity0to1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
